<template>

  <div class="column">
    <div class="mb-5" style="width: 100%; border-bottom: 1px solid #b5b5b5">
      <p class="is-size-5 mb-2">
        Informations  de contact
      </p>
    </div>

    <div class="columns is-reversed-mobile">

      <div class="column">

        <b-field label="Adresse *">
          <b-autocomplete :data="results"
                          placeholder="10 rue saint honoré"
                          field="label"
                          :loading="loading"
                          ref="autocomplete"
                          required
                          @typing="getAsyncData"
                          @select="option => onSelect(option)">

            <template slot-scope="props">
              <div class="media">
                <div class="media-left">
                  <b-icon icon="map-marker" />
                </div>
                <div class="media-content">
                  {{ props.option.label }}
                </div>
              </div>
            </template>
          </b-autocomplete>
        </b-field>

        <b-field label="Code postal *">
          <b-input v-model="form.contactInfos.address.postCode"
                   placeholder="75018"
                   required/>
        </b-field>

        <b-field label="Ville *">
          <b-input v-model="form.contactInfos.address.city"
                   placeholder="Paris"
                   required/>
        </b-field>

        <app-phone-number-input @isvalid="isTelValid = $event"
                                required
                                :initialValue="form.contactInfos.phoneNumber"
                                @update-phone-number="form.contactInfos.phoneNumber = $event" />

      </div>

      <div class="column is-two-fifths">

        <l-map ref="myMap"
               style="height: 330px;"
               v-if="form.contactInfos.address.location
                       && form.contactInfos.address.location.coordinates.length"
               :zoom="mapData.zoom"
               :center="getLatLng"
               :options="mapData.mapOptions"
               @update:center="centerUpdate"
               @update:zoom="zoomUpdate">

          <l-tile-layer :url="mapData.url"
                        :attribution="mapData.attribution" />

          <l-marker :lat-lng="getLatLng">
            <l-popup>
              <div>
                {{ form && form.name }}
              </div>
            </l-popup>
          </l-marker>

        </l-map>
        <!-- maps.google.com/?ll=38.882147,-76.99017 -->

        <div v-else class="has-text-grey-light">
          <div class="is-flex is-justify-content-center has-text-centered p-4">
            <p>Informations insuffisantes pour afficher la carte.</p>
          </div>
        </div>

      </div>

    </div>

    <div
      class="is-flex is-justify-content-flex-end pt-4 pb-4"
      :class="{ 'mb-6': isMobile }"
    >
      <b-button type="is-secondary"
                class="mr-3"
                :outlined="!isWaitingForModification || !isFormValid"
                icon-left="content-save"
                @click="saveModdification"
                :disabled="!isWaitingForModification || !isFormValid"
                :expanded="isMobile">
        Enregistrer
      </b-button>

      <b-button type="is-light"
                icon-left="undo-variant"
                @click="onCancel"
                :disabled="!isWaitingForModification"
                :expanded="isMobile">
        Annuler
      </b-button>
    </div>

  </div>

</template>

<script>
import isequal from 'lodash.isequal';
import clonedeep from 'lodash.clonedeep';
import PhoneNumberInput from '@/components/PhoneNumberInput.vue';
import debounce from 'lodash.debounce';
import { latLng } from 'leaflet';
import {
  LMap,
  LTileLayer,
  LMarker,
  LPopup,
} from 'vue2-leaflet';

export default {
  components: {
    'app-phone-number-input': PhoneNumberInput,
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
  },
  props: {
    restaurant: {
      type: Object,
      default: null,
    },
    isMobile: {
      type: Boolean,
    },
  },
  data() {
    return {
      form: {},
      results: [],
      selected: null,
      isTelValid: false,
      loading: false,
      mapData: {
        zoom: 17.5,
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        attribution:
          '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        currentZoom: 11.5,
        currentCenter: [48.866667, 2.333333],
        mapOptions: {
          zoomSnap: 0.5,
          dragging: false,
          zoomControl: false,
          scrollWheelZoom: false,
          touchZoom: false,
          doubleClickZoom: false,
          boxZoom: false,
          keyboard: false,
        },
        showMap: false,
      },
    };
  },
  watch: {
    restaurant: {
      immediate: true,
      handler(restaurantFromProps) {
        if (restaurantFromProps) {
          this.form = clonedeep(restaurantFromProps);
        }
      },
    },
  },
  mounted() {
    this.setAdressAutoComplete(this.form.contactInfos.address);
  },
  computed: {
    isWaitingForModification() {
      return !isequal(this.restaurant, this.form);
    },
    getLatLng() {
      // coordinates: [48.866667, 2.333333],
      const coordinates = this.form?.contactInfos?.address?.location?.coordinates
                            || [48.866667, 2.333333];
      const lat = coordinates[1];
      const lng = coordinates[0];

      return latLng(lat, lng);
    },
    isValidPhoneNumber() {
      return this.isTelValid;
    },
    isFormValid() {
      return this.form?.contactInfos?.address?.city?.trim()
               && this.form?.contactInfos?.address?.postCode?.trim()
               && this.form?.contactInfos?.address?.street?.trim()
               && this.isValidPhoneNumber;
    },
  },
  methods: {
    setAdressAutoComplete(option) {
      this.$refs.autocomplete.setSelected(option);
    },
    saveModdification() {
      this.$emit('update-restaurant', this.form);
    },
    onCancel() {
      this.form = clonedeep(this.restaurant);
      this.setAdressAutoComplete(this.form.contactInfos.address);
    },
    zoomUpdate(zoom) {
      this.mapData.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.mapData.currentCenter = center;
    },
    // eslint-disable-next-line func-names
    getAsyncData: debounce(async function (value) {
      this.resetAdress();
      this.form.contactInfos.address.street = value;
      this.form.contactInfos.address.label = value;
      this.results = [];

      if (!value) return;
      this.loading = true;
      try {
        const res = await fetch(this.setQuery(value));
        const data = await res.json();

        data.features.forEach((item) => {
          const formatedItem = {
            label: item?.properties?.label,
            city: item?.properties?.city,
            postCode: item?.properties?.postcode,
            cityCode: item?.properties?.citycode,
            houseNumber: item?.properties?.housenumber,
            street: item?.properties?.street,
            location: {
              type: 'Point',
              coordinates: [
                item?.geometry?.coordinates[0],
                item?.geometry?.coordinates[1],
              ],
            },
          };

          this.results.push(formatedItem);
        });
      } catch (err) {
        // eslint-disable-next-line
        console.log(err);
      } finally {
        this.loading = false;
      }
    }, 500),
    onSelect(value) {
      if (value) {
        this.form.contactInfos.address = value;
      }
    },
    resetAdress() {
      this.form = {
        ...this.form,
        contactInfos: {
          ...this.form.contactInfos,
          address: {
            ...this.form.contactInfos.address,
            label: '',
            city: '',
            postCode: undefined,
            cityCode: undefined,
            street: '',
            houseNumber: '',
            location: undefined,
          },
        },
      };
    },
    setQuery(value) {
      const requestURL = 'https://api-adresse.data.gouv.fr/search/?q=';

      return `${requestURL + value}?type=housenumber&amp;autocomplete=1`;
    },
  },
};
</script>

<style lang="scss" scoped>
  ::v-deep .leaflet-pane {
    z-index: 10;
  }
  ::v-deep .leaflet-bottom, .leaflet-top {
    z-index: 10;
  }

  @media(max-width: $tablet) {
    .columns.is-reversed-mobile {
      display: flex;
      flex-direction: column-reverse;
    }
  }
</style>