<template>

  <div class="column">
    <div class="mb-5" style="width: 100%; border-bottom: 1px solid #b5b5b5">
      <p class="is-size-5 mb-2">
        Horaire d'ouvertures
      </p>
    </div>

    <div>
      <b-field class="mb-4">
        <b-switch
          v-model="form.businessHours.activated"
          type="is-secondary"
          style="font-weight: 700;"
        >
          Activer l'affichage des horaires d'ouverture
        </b-switch>
      </b-field>
    </div>

    <div v-if="form && form.businessHours.activated" class="mt-5">

      <div>
        <b-button :type="(activeDay === idx) ? 'is-primary' : ''"
                  @click="activeDay = idx"
                  v-for="(day, idx) in form.businessHours.days"
                  :key="idx"
                  class="mr-2"
                  :class="{ 'mb-2': isMobile }">
          {{ form.businessHours.days[idx].label }}
        </b-button>
      </div>

      <div>
        <b-field class="mt-6">
          <b-switch v-model="form.businessHours.days[activeDay].open"
                    size="is-medium"
                    type="is-secondary">
            Ouvert
          </b-switch>
        </b-field>
      </div>

      <div class="is-flex
                  is-flex-wrap-wrap
                  is-align-items-center
                  mt-5 mb-6">

        <template v-if="hasActiveDay">
          <div v-for="(hour, index) in form.businessHours.days[activeDay].hours"
               :key="index"
               class="is-flex is-align-items-center mt-3"
               style="border-left: 1px solid #7289da">

            <b-timepicker :value="new Date(hour.start_time)"
                          @input="updateDate(index, 'start_time', $event)"
                          inline
                          hour-format="24"
                          :incrementMinutes="minutesGranularity"
                          size="is-small"
                          class="pr-2" />

            <span> - </span>

            <b-timepicker :value="new Date(hour.end_time)"
                          @input="updateDate(index, 'end_time', $event)"
                          inline
                          hour-format="24"
                          :incrementMinutes="minutesGranularity"
                          size="is-small"
                          class="pr-2 pl-2" />

            <b-icon icon="delete"
                    type="is-danger"
                    class="mr-5"
                    style="cursor: pointer;"
                    @click.native="deleteHour(activeDay, index)" />

          </div>
        </template>

        <p v-else class="p-5">Aucune plages horaires renseignées</p>

      </div>

      <div>
        <b-button type="is-ligth"
                  outlined
                  icon-left="plus"
                  class="mt-2"
                  :disabled="isAddHourBtnDisabled"
                  @click="addHour"
                  :expanded="isMobile">
          Ajouter plage horaire
        </b-button>
      </div>

    </div>

    <div class="is-flex is-justify-content-flex-end pt-4 pb-4">
      <b-button type="is-secondary"
                class="mr-3"
                :outlined="!isWaitingForModification"
                icon-left="content-save"
                @click="saveModdification"
                :disabled="!isWaitingForModification"
                :expanded="isMobile">
        Enregistrer
      </b-button>

      <b-button type="is-light"
                icon-left="undo-variant"
                @click="onCancel"
                :disabled="!isWaitingForModification"
                :expanded="isMobile">
        Annuler
      </b-button>
    </div>

  </div>

</template>

<script>
import moment from 'moment';
import isequal from 'lodash.isequal';
import clonedeep from 'lodash.clonedeep';

export default {
  props: {
    restaurant: {
      type: Object,
      default: null,
    },
    isMobile: {
      type: Boolean,
    },
  },
  data() {
    return {
      form: {},
      activeDay: 0,
      minutesGranularity: 15,
    };
  },
  watch: {
    restaurant: {
      immediate: true,
      handler(restaurantFromProps) {
        if (restaurantFromProps) {
          this.form = clonedeep(restaurantFromProps);
        }
      },
    },
  },
  methods: {
    updateDate(index, timeType, value) {
      // eslint-disable-next-line no-unused-expressions
      this.form.businessHours.days[this.activeDay].hours[index][timeType] = value;
    },
    onCancel() {
      this.form = clonedeep(this.restaurant);
    },
    saveModdification() {
      const restaurantToUpdate = this.form;
      const businessHours = {
        ...this.form.businessHours,
        updatedAt: moment().toDate(),
      };

      restaurantToUpdate.businessHours = businessHours;
      this.$emit('update-restaurant', restaurantToUpdate);
    },
    addHour() {
      const nbrOfHours = this.form.businessHours.days[this.activeDay].hours.length;

      if (nbrOfHours < 5) {
        const min = new Date();
        const max = new Date();

        min.setHours(9);
        min.setMinutes(0);
        max.setHours(18);
        max.setMinutes(0);

        this.form.businessHours.days[this.activeDay].hours.push({
          start_time: min,
          end_time: max,
        });
      }
    },
    deleteHour(dayIdx, id) {
      this.form.businessHours.days[dayIdx].hours.splice(id, 1);
    },
  },
  computed: {
    isWaitingForModification() {
      return !isequal(this.restaurant, this.form);
    },
    hasActiveDay() {
      return this.form?.businessHours?.days[this.activeDay]?.hours?.length > 0;
    },
    isAddHourBtnDisabled() {
      return this.form?.businessHours?.days[this.activeDay]?.hours?.length >= 5;
    },
    isFormValid() {
      return true;
    },
  },
};
</script>

<style scoped lang="scss">
  .border-b {
    border-bottom: 1px solid #e8ebeb;
  }
</style>