<template>

  <div class="column">
    <div class="mb-5" style="width: 100%; border-bottom: 1px solid #b5b5b5">
      <p class="is-size-5 mb-2">
        Marketing
      </p>
    </div>

    <div class="border-b">
      <b-field class="mb-6">
        <b-switch
          v-model="form.isEventBannerActivated"
          type="is-secondary"
          class="label"
        >
          Activer bannière événementielle
        </b-switch>
      </b-field>
    </div>

    <div v-show="form.isEventBannerActivated" class="mt-5">
      <b-field label="Description de l'évènement">
        <b-input v-model="form.eventBannerDescription"
                  placeholder="Description"
                  maxlength="350"
                  rows.native="4"
                  type="textarea"
                  custom-class="text-area_input" />
      </b-field>
    </div>

    <!-- <div class="border-b">
      <b-field class="mb-4">
        <b-switch v-model="form.isGlobalPromotionActivated"
                  size="is-medium"
                  type="is-secondary">
          Activer promotion globale
        </b-switch>
      </b-field>
    </div> -->

    <b-field
      :type="{
        'is-danger': aliasErrors.length > 0,
        'is-success': form.alias && aliasErrors.length === 0
                                && isWaitingForModification
                                && !isCheckingAlias,
      }"
      :message="aliasErrors"
      grouped
    >
      <template #label>
        URL personnalisée
        <b-icon
          size="is-small"
          icon="help-circle-outline"
          class="is-clickable"
          @click.native="isAliasHelperDisplayed = true"
        />
      </template>
      <div v-if="!isMobile" class="base-url">{{ baseUrl }}</div>
      <b-input
        maxlength="250"
        placeholder="nom-du-restaurant"
        :loading="isCheckingAlias"
        v-model.trim="form.alias"
        @input="onAliasInput"
        expanded
      />
      <p class="control">
        <b-button
          class="button is-primary"
          icon-left="vector-link"
          :disabled="!form.alias || aliasErrors.length !== 0"
          @click="copyUrlAlias"
        >
          <span v-if="!isMobile">copier</span>
        </b-button>
      </p>
    </b-field>

    <b-notification
      type="is-dark"
      has-icon
      icon="lightbulb-on-outline"
      role="alert"
      v-model="isAliasHelperDisplayed"
    >
      <p class="has-text-justified">
        Les restaurants avec un alias peuvent générer des URL personnalisées
        permettant à vos clients d'y accéder facilement.
        L'alias doit faire au minimum 5 caractères et
        être composé de caractères alphanumériques ou de tirets (-).
        L’utilisation de majuscules ne permet pas
        de distinguer des alias similaires.
        Par exemple, le-Resto-Du-55 et le-resto-du-55 sont considérés
        comme un seul et même alias.
      </p>
    </b-notification>

    <b-field>
      <b-switch
        v-model="form.isTakeawayActivated"
        type="is-secondary"
        style="font-weight: 700;"
      >
        Activer la vente à emporter
      </b-switch>
      <div class="control is-flex is-align-items-center">
        <b-icon
          size="is-small"
          icon="help-circle-outline"
          class="is-clickable"
          @click.native="isTakeAwayHelperDisplayed = true"
        />
      </div>
    </b-field>

    <b-notification
      type="is-dark"
      has-icon
      icon="lightbulb-on-outline"
      role="alert"
      v-model="isTakeAwayHelperDisplayed"
    >
      <p class="has-text-justified">
        Activer la "Vente à emporter" signifie aux clients
        que votre menu est disponible pour la vente à emporter.
        Ils peuvent ainsi vous appeler
        et vous passer la commande par téléphone et payer sur place.
        Pour cela,
        merci de remplir vos coordonnées téléphoniques.
      </p>
    </b-notification>

    <div class="is-flex is-justify-content-flex-end pt-4 pb-6"
          :class="{ 'mb-6': isMobile }">
      <b-button type="is-secondary"
                class="mr-3"
                :outlined="!isFormValid"
                icon-left="content-save"
                @click="saveModdification"
                :disabled="!isFormValid"
                :expanded="isMobile">
        Enregistrer
      </b-button>

      <b-button type="is-light"
                icon-left="undo-variant"
                @click="onCancel"
                :disabled="!isWaitingForModification"
                :expanded="isMobile">
        Annuler
      </b-button>
    </div>

  </div>

</template>

<script>
import isequal from 'lodash.isequal';
import clonedeep from 'lodash.clonedeep';
import debounce from 'lodash.debounce';

export default {
  props: {
    restaurant: {
      type: Object,
      default: null,
    },
    isMobile: {
      type: Boolean,
    },
  },
  data() {
    return {
      form: {},
      aliasErrors: [],
      isCheckingAlias: false,
      isAliasHelperDisplayed: false,
      covidToggleLoading: false,
      isDeleteDialogDisplayed: false,
      TakeawayToggleLoading: false,
      activeSettingMenu: false,
      isCropperModaleActive: false,
      imageToCrop: null,
      dropFile: null,
      isDeleteImageLoading: false,
      isTakeAwayHelperDisplayed: false,
    };
  },
  watch: {
    restaurant: {
      immediate: true,
      handler(restaurantFromProps) {
        if (restaurantFromProps) {
          this.form = clonedeep(restaurantFromProps);
        }
      },
    },
  },
  computed: {
    isWaitingForModification() {
      return !isequal(this.restaurant, this.form);
    },
    isFormValid() {
      return this.isWaitingForModification && this.aliasErrors.length === 0
                                           && !this.isCheckingAlias;
    },
    baseUrl() {
      return `${process.env.VUE_APP_BASE_URL}/restaurants/`;
    },
  },
  methods: {
    onAliasInput(value) {
      this.aliasErrors = [];
      if (value === '') return;
      this.isCheckingAlias = true;
      this.delayAliasTouch(value);
    },
    // eslint-disable-next-line
    delayAliasTouch: debounce(async function (value) {
      const id = this.restaurant._id;

      try {
        await this.$http.get(
          `${process.env.VUE_APP_API_URL}/restaurants/${id}/aliases/check-validity?alias=${value}`,
        );

        this.aliasErrors = [];
      } catch (err) {
        this.aliasErrors = err.response.data.errors;
      } finally {
        this.isCheckingAlias = false;
      }
    }, 200),
    async copyUrlAlias() {
      this.updateClipboard(this.form.alias);
    },
    async updateClipboard(newClip) {
      if (navigator.clipboard) {
        try {
          await navigator.clipboard.writeText(`${this.baseUrl}${newClip}`);
          // clipboard successfully set
          this.$buefy.toast.open({
            message: 'URL copiée !',
            position: 'is-bottom',
            type: 'is-success',
          });
        } catch (error) {
          // clipboard write failed
          this.$buefy.toast.open({
            duration: 5000,
            message: 'Impossible de copier le lien dans le presse papier',
            position: 'is-bottom',
            type: 'is-danger',
          });
        }
      }
    },
    saveModdification() {
      this.$emit('update-restaurant', this.form);
    },
    onCancel() {
      this.form = { ...this.restaurant };
    },
    async onFileChange(file) {
      if (file) {
        const filesize = ((file.size / 1024) / 1024).toFixed(4); // MB

        if (filesize <= 5) {
          this.createImage(file);
        } else {
          this.$buefy.snackbar.open({
            indefinite: true,
            type: 'is-danger',
            message: 'Veuillez sélectionner une image (.jpeg, .png) dont la taille est inférieure à 5MB.',
          });
        }
      }
    },
    createImage(file) {
      const reader = new FileReader();
      const vm = this;

      reader.onload = (e) => {
        vm.imageToCrop = e.target.result;
        vm.isCropperModaleActive = true;
      };
      reader.readAsDataURL(file);
    },
    uploadLogo(canvas) {
      this.isCropperModaleActive = false;

      try {
        if (canvas) {
          canvas.toBlob(
            async (blob) => {
              const loadingComponent = this.$buefy.loading.open({
                container: null,
              });
              const form = new FormData();

              form.append('file', blob);

              const id = this.form._id;
              const res = await this.$http.post(
                `${process.env.VUE_APP_API_URL}/restaurants/${id}/files/logos/upload`,
                form,
              );
              const updatedRestaurant = {
                ...this.restaurant,
                logo: { ...res.data },
              };

              if (updatedRestaurant) {
                this.$emit('add-image', updatedRestaurant);
              }
              loadingComponent.close();
            },
            this.dropFile.type,
          );
        }
      } catch (err) {
        this.$buefy.snackbar.open({
          indefinite: true,
          type: 'is-danger',
          message: 'Une erreur est survenue lors de l\'enregistrement de l\'image.',
        });
      }
    },
    async deleteImage() {
      this.isDeleteImageLoading = true;

      try {
        const id = this.form._id;
        const filename = this.form.logo && this.form.logo.filename;

        await this.$http.delete(
          `${process.env.VUE_APP_API_URL}/restaurants/${id}/files/logos/${filename}/delete`,
        );

        const updatedRestaurant = this.form;

        delete updatedRestaurant.logo;
        this.$emit('delete-image', updatedRestaurant);
      } catch (err) {
        this.$buefy.snackbar.open({
          indefinite: true,
          type: 'is-danger',
          message: 'Une erreur est survenue lors de la tentative de suppression de l\'image.',
        });
      } finally { this.isDeleteImageLoading = false; }
    },
    onCropperClose() {
      this.isCropperModaleActive = false;
    },
  },
};
</script>

<style scoped lang="scss">
  ::v-deep .text-area_input {
    max-height: 8em !important;
    min-height: 6em !important;
  }
  ul {
    margin: 0 !important;

    li {
      display: flex;
      justify-content: space-between;

      .switch {
        margin: 0;
      }
    }
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover
  }
  .base-url {
    align-items: center;
    border: 1px solid transparent;
    border-radius: 4px;
    box-shadow: none;
    display: inline-flex;
    font-size: 1rem;
    height: 2.5em;
    justify-content: flex-start;
    line-height: 1.5;
    padding-bottom: calc(0.5em - 1px);
    padding-right: calc(0.75em - 1px);
    padding-top: calc(0.5em - 1px);
    position: relative;
    vertical-align: top;
  }
  ::v-deep .mdi-lightbulb-on-outline {
    color: rgb(250, 220, 110);
  }

  @media(max-width: $tablet) {
    .columns.is-reversed-mobile {
      display: flex;
      flex-direction: column-reverse;
    }
  }
</style>