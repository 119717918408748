<template>
  <div class="column p-0 mb-4" style="position: relative;">

    <div v-if="restaurant.backgroundImage && restaurant.backgroundImage.url">
      <b-image :src="restaurant.backgroundImage.url"
               alt="Image de fond"
               lazy
               class="m-0" />
    </div>

    <b-field v-else>
      <b-upload v-model="dropFile"
                drag-drop
                accept="image/jpeg, image/png"
                native
                expanded
                rounded
                @input="onFileChange">
        <section class="section">
          <div class="content has-text-centered">
            <p>
              <b-icon icon="upload" size="is-large" />
            </p>
            <p class="has-text-centered has-text-grey is-size-6">
              Déposez votre fichier ici <br />
              Format d'image requis: JPG, PNG jusqu'à 5MB.
            </p>
          </div>
        </section>
      </b-upload>
    </b-field>

    <div style="position: absolute; bottom: 10px; right: 10px;">
      <div class="is-flex is-align-items-center">
        <b-button type="is-light"
                  icon-left="delete"
                  :loading="isDeleteImageLoading"
                  @click="deleteImage"
                  v-if="restaurant.backgroundImage"
                  expanded>
          Supprimer image de fond
        </b-button>
      </div>
    </div>

    <b-modal v-model="isCropperModaleActive"
             has-modal-card
             trap-focus
             :can-cancel="['x']"
             :destroy-on-hide="true"
             aria-role="dialog"
             aria-label="Image cropper"
             aria-modal>
      <template #default="props">
        <app-image-cropper v-bind="{
                             src: imageToCrop,
                             stencilAspectRatio: 4.45,
                           }"
                           @close="props.close"
                           @uploadLogo="uploadLogo" />
      </template>
    </b-modal>

  </div>
</template>

<script>
import ImageCropper from '@/components/back_office/ImageCropper.vue';
import imageCompression from 'browser-image-compression';

export default {
  components: {
    'app-image-cropper': ImageCropper,
  },
  props: {
    restaurant: {
      type: Object,
      default: null,
    },
    isMobile: {
      type: Boolean,
    },
  },
  data() {
    return {
      isCropperModaleActive: false,
      imageToCrop: null,
      dropFile: null,
      isDeleteImageLoading: false,
    };
  },
  methods: {
    async onFileChange(file) {
      if (file) {
        const filesize = ((file.size / 1024) / 1024).toFixed(4); // MB

        if (filesize <= 5) {
          this.createImage(file);
        } else {
          this.$buefy.snackbar.open({
            indefinite: true,
            type: 'is-danger',
            message: 'Veuillez sélectionner une image (.jpeg, .png) dont la taille est inférieure à 5MB.',
          });
        }
      }
    },
    async createImage(imageFile) {
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };

      try {
        const compressedFile = await imageCompression(imageFile, options);

        // console.log(`compressedFile size before crop ${compressedFile.size / 1024 / 1024} MB`);
        const reader = new FileReader();
        const vm = this;

        reader.onload = (e) => {
          vm.imageToCrop = e.target.result;
          vm.isCropperModaleActive = true;
        };
        reader.readAsDataURL(compressedFile);
      } catch (error) {
        console.log(error);
      }
    },
    uploadLogo(canvas) {
      this.isCropperModaleActive = false;

      try {
        if (canvas) {
          canvas.toBlob(
            async (blob) => {
              const loadingComponent = this.$buefy.loading.open({
                container: null,
              });
              const form = new FormData();

              form.append('file', blob);

              const id = this.restaurant._id;
              const res = await this.$http.post(
                `${process.env.VUE_APP_API_URL}/restaurants/${id}/files/background-img/upload`,
                form,
              );
              const updatedRestaurant = {
                ...this.restaurant,
                backgroundImage: { ...res.data },
              };

              if (updatedRestaurant) {
                this.$emit('add-image', updatedRestaurant);
              }
              loadingComponent.close();
            },
            this.dropFile.type,
          );
        }
      } catch (err) {
        this.$buefy.snackbar.open({
          indefinite: true,
          type: 'is-danger',
          message: 'Une erreur est survenue lors de l\'enregistrement de l\'image.',
        });
      }
    },
    async deleteImage() {
      this.isDeleteImageLoading = true;

      try {
        const id = this.restaurant._id;
        const filename = this.restaurant.backgroundImage
                           && this.restaurant.backgroundImage.filename;

        await this.$http.delete(
          `${process.env.VUE_APP_API_URL}/restaurants/${id}/files/background-img/${filename}/delete`,
        );

        const updatedRestaurant = this.restaurant;

        delete updatedRestaurant.backgroundImage;
        this.$emit('delete-image', updatedRestaurant);
      } catch (err) {
        this.$buefy.snackbar.open({
          indefinite: true,
          type: 'is-danger',
          message: 'Une erreur est survenue lors de la tentative de suppression de l\'image.',
        });
      } finally { this.isDeleteImageLoading = false; }
    },
    onCropperClose() {
      this.isCropperModaleActive = false;
    },
  },
};
</script>

<style lang="scss" scoped>
  ::v-deep img {
    border-radius: 5px;
  }
</style>