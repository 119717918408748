<template>
  <div class="column">
    <div class="mb-5" style="width: 100%; border-bottom: 1px solid #b5b5b5">
      <p class="is-size-5 mb-2 has-text-danger">
        Supprimer l'établissement
      </p>
    </div>

    <div>
      <p class="is-size-6">
        <span class="has-text-weight-bold">ATTENTION :</span> La suppression de l'établissement
        <span class="has-text-weight-bold">est irréversible.</span>
      </p>
    </div>

    <div class="is-flex is-justify-content-flex-end pt-4 pb-6"
          :class="{ 'mb-6': isMobile }">
      <b-button
        type="is-danger"
        class="mr-3"
        :outlined="false"
        icon-left="delete-outline"
        :expanded="isMobile"
        @click.native="confirmDelete"
      >
        Supprimer
      </b-button>
    </div>
  </div>

</template>

<script>

export default {
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    confirmDelete() {
      this.$buefy.dialog.confirm({
        title: "Suppression de l'établissement",
        message: `
          <div class="has-text-justified">
            Êtes vous sûr(e) de vouloir supprimer ce restaurant ? </br>
            L'ensemble des menus associés seront également supprimés.
          </div>
        `,
        cancelText: 'Annuler',
        confirmText: 'Supprimer',
        type: 'is-danger',
        hasIcon: false,
        onConfirm: () => this.deleteRestaurant(),
      });
    },
    async deleteRestaurant() {
      const loadingComponent = this.$buefy.loading.open({
        container: null,
      });

      try {
        await this.$http.delete(
          `${process.env.VUE_APP_API_URL}/restaurants/${this.$route.params.restaurantId}`,
        );
        this.$router.push({ name: 'BO_Home' });
        this.$buefy.toast.open({
          message: 'Restaurant supprimé avec succès.',
          type: 'is-success',
          position: 'is-bottom',
        });
      } catch (err) {
        this.$buefy.snackbar.open({
          indefinite: true,
          type: 'is-danger',
          message: 'Impossible de supprimer le restaurant.',
        });
      } finally { loadingComponent.close(); }
    },
  },
};
</script>

<style scoped lang="scss">
</style>