<template>
  <div style="flex: 1">
    <restaurant-important-information
      :restaurant="restaurant"
      :isMobile="isMobile"
      v-on="$listeners"
    />

    <restaurant-contact-form
      :restaurant="restaurant"
      :isMobile="isMobile"
      v-on="$listeners"
    />

    <restaurant-hours
      :restaurant="restaurant"
      :isMobile="isMobile"
      v-on="$listeners"
    />

    <restaurant-marketing-form
      :restaurant="restaurant"
      :isMobile="isMobile"
      v-on="$listeners"
    />

    <restaurant-delete :isMobile="isMobile" />
  </div>
</template>

<script>
import RestaurantContactForm from './RestaurantContactForm.vue';
import RestaurantDelete from './RestaurantDelete.vue';
import RestaurantHours from './RestaurantHours.vue';
import RestaurantImportantInformation from './RestaurantImportantInformation.vue';
import RestaurantMarketingForm from './RestaurantMarketingForm.vue';

export default {
  components: {
    RestaurantImportantInformation,
    RestaurantContactForm,
    RestaurantHours,
    RestaurantMarketingForm,
    RestaurantDelete,
  },
  props: {
    restaurant: {
      type: Object,
      default: null,
    },
    isMobile: {
      type: Boolean,
    },
  },
  // data() {},
  watch: {},
  computed: {},
  methods: {},
};
</script>

<style scoped lang="scss">
</style>