<template>

  <div class="column">
    <div class="mb-5" style="width: 100%; border-bottom: 1px solid #b5b5b5">
      <p class="is-size-5 mb-2">
        Informations importantes
      </p>
    </div>

    <div class="columns is-reversed-mobile">

      <div class="column">

        <b-field label="Nom de l'établissement">
          <b-input
            v-model.trim="form.name"
            maxlength="200"
            placeholder="Nom du restaurant"
          />
        </b-field>

        <b-field label="Description de l'établissement">
          <b-input
            v-model="form.description"
            placeholder="Description du restaurant"
            maxlength="450"
            rows.native="4"
            type="textarea"
            custom-class="text-area_input"
          />
        </b-field>

        <b-field label="Photo de couverture">
          <app-background-image-upload
            :restaurant="form"
            :isMobile="isMobile"
            @add-image="$emit('add-image', $event)"
            @delete-image="$emit('delete-image', $event)"
          />
        </b-field>

      </div>

      <div
        class="column
               is-one-third
               is-flex
               is-flex-direction-column
               is-justify-content-flex-start
               is-align-items-center"
      >

        <div
          v-if="form.logo && form.logo.url"
          style="max-width: 70%;"
        >
          <b-image
            :src="form.logo.url"
            alt="Logo restaurant"
            lazy
            rounded
            class="m-0"
          />
        </div>

        <b-field v-else label="Logo de l'établissement">
          <b-upload
            v-model="dropFile"
            drag-drop
            accept="image/jpeg, image/png"
            native
            rounded
            @input="onFileChange"
          >
            <section class="section">
              <div class="content has-text-centered">
                <p>
                  <b-icon icon="upload" size="is-large" />
                </p>
                <p class="has-text-centered has-text-grey is-size-6">
                  Déposez votre fichier ici <br />
                  Format d'image requis: JPG, PNG jusqu'à 5MB.
                </p>
              </div>
            </section>
          </b-upload>
        </b-field>

        <div>
          <div class="is-flex is-align-items-center">
            <b-button
              type="is-light"
              icon-left="delete"
              :loading="isDeleteImageLoading"
              @click="deleteImage"
              v-if="form.logo"
              expanded
            >
              Supprimer image de profil
            </b-button>
          </div>

        </div>
      </div>

    </div>

    <div
      class="is-flex is-justify-content-flex-end pt-4 pb-4"
      :class="{ 'mb-6': isMobile }"
    >
      <b-button
        type="is-secondary"
        class="mr-3"
        :outlined="!isFormValid"
        icon-left="content-save"
        @click="saveModdification"
        :disabled="!isFormValid"
        :expanded="isMobile"
      >
        Enregistrer
      </b-button>

      <b-button
        type="is-light"
        icon-left="undo-variant"
        @click="onCancel"
        :disabled="!isWaitingForModification"
        :expanded="isMobile"
      >
        Annuler
      </b-button>
    </div>

    <b-modal
      v-model="isCropperModaleActive"
      has-modal-card
      trap-focus
      :can-cancel="['x']"
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-label="Image cropper"
      aria-modal
    >
      <template #default="props">
        <app-image-cropper
          v-bind="{
            src: imageToCrop,
            stencilType: 'circle-stencil'
          }"
          @close="props.close"
          @uploadLogo="uploadLogo"
        />
      </template>
    </b-modal>

  </div>

</template>

<script>
import isequal from 'lodash.isequal';
import clonedeep from 'lodash.clonedeep';
import imageCompression from 'browser-image-compression';

import ImageCropper from '@/components/back_office/ImageCropper.vue';
import BackgroundImageUpload from '@/components/back_office/BackgroundImageUpload.vue';

export default {
  components: {
    'app-image-cropper': ImageCropper,
    'app-background-image-upload': BackgroundImageUpload,
  },
  props: {
    restaurant: {
      type: Object,
      default: null,
    },
    isMobile: {
      type: Boolean,
    },
  },
  data() {
    return {
      form: {},
      covidToggleLoading: false,
      isDeleteDialogDisplayed: false,
      TakeawayToggleLoading: false,
      activeSettingMenu: false,
      isCropperModaleActive: false,
      imageToCrop: null,
      dropFile: null,
      isDeleteImageLoading: false,
    };
  },
  watch: {
    restaurant: {
      immediate: true,
      handler(restaurantFromProps) {
        if (restaurantFromProps) {
          this.form = clonedeep(restaurantFromProps);
        }
      },
    },
  },
  computed: {
    isWaitingForModification() {
      return !isequal(this.restaurant, this.form);
    },
    isFormValid() {
      return this.isWaitingForModification && this.form.name.trim();
    },
    baseUrl() {
      return `${process.env.VUE_APP_BASE_URL}/site/`;
    },
  },
  methods: {
    saveModdification() {
      this.$emit('update-restaurant', this.form);
    },
    onCancel() {
      this.form = { ...this.restaurant };
    },
    async onFileChange(file) {
      if (file) {
        const filesize = ((file.size / 1024) / 1024).toFixed(4); // MB

        if (filesize <= 5) {
          this.createImage(file);
        } else {
          this.$buefy.snackbar.open({
            indefinite: true,
            type: 'is-danger',
            message: 'Veuillez sélectionner une image (.jpeg, .png) dont la taille est inférieure à 5MB.',
          });
        }
      }
    },
    async createImage(imageFile) {
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 512, // 128 is ok for dish cards but not for dialog card
        useWebWorker: true,
      };

      try {
        const compressedFile = await imageCompression(imageFile, options);

        // console.log(`compressedFile size before crop ${compressedFile.size / 1024 / 1024} MB`);
        const reader = new FileReader();
        const vm = this;

        reader.onload = (e) => {
          vm.imageToCrop = e.target.result;
          vm.isCropperModaleActive = true;
        };
        reader.readAsDataURL(compressedFile);
      } catch (error) {
        console.log(error);
      }
    },
    uploadLogo(canvas) {
      this.isCropperModaleActive = false;

      try {
        if (canvas) {
          canvas.toBlob(
            async (blob) => {
              const loadingComponent = this.$buefy.loading.open({
                container: null,
              });
              const form = new FormData();

              form.append('file', blob);

              const id = this.form._id;
              const res = await this.$http.post(
                `${process.env.VUE_APP_API_URL}/restaurants/${id}/files/logos/upload`,
                form,
              );
              const updatedRestaurant = {
                ...this.restaurant,
                logo: { ...res.data },
              };

              if (updatedRestaurant) {
                this.$emit('add-image', updatedRestaurant);
              }
              loadingComponent.close();
            },
            this.dropFile.type,
          );
        }
      } catch (err) {
        this.$buefy.snackbar.open({
          indefinite: true,
          type: 'is-danger',
          message: 'Une erreur est survenue lors de l\'enregistrement de l\'image.',
        });
      }
    },
    async deleteImage() {
      this.isDeleteImageLoading = true;

      try {
        const id = this.form._id;
        const filename = this.form.logo && this.form.logo.filename;

        await this.$http.delete(
          `${process.env.VUE_APP_API_URL}/restaurants/${id}/files/logos/${filename}/delete`,
        );

        const updatedRestaurant = this.form;

        delete updatedRestaurant.logo;
        this.$emit('delete-image', updatedRestaurant);
      } catch (err) {
        this.$buefy.snackbar.open({
          indefinite: true,
          type: 'is-danger',
          message: 'Une erreur est survenue lors de la tentative de suppression de l\'image.',
        });
      } finally { this.isDeleteImageLoading = false; }
    },
    onCropperClose() {
      this.isCropperModaleActive = false;
    },
  },
};
</script>

<style scoped lang="scss">
  ::v-deep .text-area_input {
    max-height: 8em !important;
    min-height: 6em !important;
  }
  ul {
    margin: 0 !important;

    li {
      display: flex;
      justify-content: space-between;

      .switch {
        margin: 0;
      }
    }
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover
  }
  .base-url {
    align-items: center;
    border: 1px solid transparent;
    border-radius: 4px;
    box-shadow: none;
    display: inline-flex;
    font-size: 1rem;
    height: 2.5em;
    justify-content: flex-start;
    line-height: 1.5;
    padding-bottom: calc(0.5em - 1px);
    padding-right: calc(0.75em - 1px);
    padding-top: calc(0.5em - 1px);
    position: relative;
    vertical-align: top;
  }
  ::v-deep .mdi-lightbulb-on-outline {
    color: rgb(250, 220, 110);
  }

  @media(max-width: $tablet) {
    .columns.is-reversed-mobile {
      display: flex;
      flex-direction: column-reverse;
    }
  }
</style>